.arl-detail_header-text {
  font-family: 'Poppins';
  font-weight: 600;
  font-family: 'Poppins';
  font-style: normal;
  text-transform: uppercase;
  color: #1e1e1e;
  line-height: 28.8px;
  font-size: 14px;
}

.arl-detail-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  overflow: auto;
}

.arl-card-content-wrapper {
  padding: 8px 4px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.arl-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 8px 6px;
}

.arl-card-content__text {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
}

.arl-card-content-wrapper-text {
  font-family: 'Poppins';
  padding: 8px 10px;
  color: #fff;
  background-color: #cc435f;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.arl-card-holder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
}

.arl-card-content__btn {
  width: fit-content;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #f5f5f5;
  background-color: #5a5a5a;
}

.arl-card-content__update-btn {
  width: fit-content;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #f5f5f5;
  background-color: #1976d2;
}

.arl-card-triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid white;
  position: relative;
  left: 90%;
}

.arl-card-condition-wrapper {
  padding: 8px 4px;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  justify-content: space-around;
  gap: 10px;
}

.arl-card-condition-popup {
  width: 100%;
  position: absolute;
}

.arl-card-container {
  position: relative;
  border-radius: 8px;
}

.arl-single-card-cover {
  position: relative;
}

.arl-dropdown {
  width: 80%;
  padding: 8px;
  appearance: none;
  background: #f2f2f2 url('../../../../Assets/ContentLibrary/ContentLibrary/MainBar/dropdown.png') no-repeat 93% 50%;
  outline: none;
  border: 1px solid #f2f2f2;
  border-radius: 16px;
}

.arl-condition-input {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 8px;
  padding: 8px;
  width: 20%;
  text-align: center;
  outline: none;
}

.arl-condition-input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.arl-card-condition__btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  gap: 20px;
}

.arl-card-condition__cancel-btn {
  padding: 8px;
  width: fit-content;
  border: 1px solid #767676;
  outline: none;
  border-radius: 8px;
  color: #1e1e1e;
  background-color: #e3e3e3;
}

.arl-card-condition__save-btn {
  padding: 8px;
  width: fit-content;
  border: 1px solid #02542d;
  outline: none;
  border-radius: 8px;
  color: #f5f5f5;
  background-color: #14ae5c;
}

.arl-card-condition-definition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  border-bottom: 1px solid #d9d9d9;
  padding: 4px;
}
