.settings-main-bar {
    height: 100vh;
    width: calc(100% - 260px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.settings-main-bar__header {
    position: sticky;
    top: 0px;
    height: 56px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
}

.settings-header-text {
    display: inline-block;
    margin: 15px 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #353131;
}

.settings-main-bar__form {
    position: relative;
    width: 100%;
    height: calc(100% - 56px);
    background: #EFF3FD;
    overflow-y: scroll;
}

.settings-form-checkbox-holder {
    height: calc(100% - 82px);
    display: flex;
    width: 1000px;
    flex-wrap: wrap;
    gap: 35px;
}

.settings-main-bar__form-submission {
    position: inherit;
    width: auto;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: space-between;
    align-items: center;
}