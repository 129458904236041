.playKit-btn-holder {
    position: relative;
    display: flex;
    min-height: auto;
    max-height: 100px;
    width: 100%;
    overflow-y: auto;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -20px;
    flex-wrap: wrap;
}

.playKit-btn-holder__btn {
    position: relative;
    width: auto;
    height: auto;
    min-height: 30px;
    min-width: 98px;
    background: #FFDEB6;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.playKit-btn-holder__btn-description {
    position: relative;
    min-height: 17px;
    height: auto;
    max-width: 200px;
    word-break: normal;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2E2D32;
    margin-left: 5px;
    margin-right: 5px;
}