.playkit-table {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.playkit-table-responsive {
  width: 98%;
  margin-left: -0.25%;
  height: 90%;
  overflow-y: auto;
}

.playkit-table-display {
  width: 100%;
  /* height: 100%; */
}

.playkit-table-display thead {
  border-radius: 10px;
  border: 1px solid rgba(166, 200, 166, 0.25);
  background: #dceedc;
}

.playkit-table-display thead tr {
  top: 0;
  height: 12%;
  width: 100%;
  position: sticky;
  background: #dceedc;
  /* border: 1px solid; */
}

.playkit-table-display tbody tr {
  background-color: #fff;
  border-radius: 8px;
  border-bottom: 1px solid grey;
  cursor: pointer;
}

.playkit-table-display thead tr td {
  /* text-align: left; */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.playkit-table-display tbody tr td {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.playkit-table-display thead tr td:nth-child(1) {
  width: 2%;
}
.playkit-table-display thead tr td:nth-child(2) {
  width: 20%;
}
.playkit-table-display thead tr td:nth-child(3) {
  width: 68%;
}
.playkit-table-display thead tr td:nth-child(4) {
  width: 10%;
  text-align: -webkit-center;
}

.playkit-table-display tbody tr td:nth-child(1),
.playkit-table-display tbody tr td:nth-child(2),
.playkit-table-display tbody tr td:nth-child(3) {
  cursor: auto;
}

.playkit-table-display tbody tr td:nth-child(4) {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: auto;
}
