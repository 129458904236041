.toggle-switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 26px;
    cursor: pointer;
    background-color: rgba(229, 229, 229, 1);
    border-radius: 27px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}

.toggle-switch input[type="checkbox"] {
    display: none;
}

.toggle-switch__slider {
    position: absolute;
    cursor: pointer;
    content: "";
    left: 3px;
    top: 2px;
    width: 21px;
    height: 21px;
    background-color: #2F80ED;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked+.toggle-switch__slider {
    transform: translateX(27px);
    background-color: #2F80ED;
}