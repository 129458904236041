.activity-description-info {
    width: 180px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
    display: block;
    text-align: left;
}

.activity-description-info__img {
    display: inline-block;
    vertical-align: middle;
    height: auto;
    width: auto;
}

.activity-description-info__title {
    display: inline-block;
    margin-left: 20px;
    color: #333333;
}