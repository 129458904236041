.checkbox-category {
    width: auto;
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #2F80ED;
    margin-bottom: 16px;
}