.side-bar {
  height: 100vh;
  width: 260px;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.side-bar__menu-btns {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.menu-btn-modules {
  position: relative;
  top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.side-bar__school-info {
  position: relative;
  min-height: 220px;
  width: 85%;
  top: 21px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 5px 15px rgba(211, 211, 211, 0.409828);
  border-radius: 14px;
  z-index: 5;
}

.logout-btn {
  position: relative;
  width: 100%;
  height: auto;
  border: none;
  outline: none;
  padding: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: rgba(255, 255, 255, 1);
  background: rgba(204, 67, 95, 1);
  display: block;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}
