.add-content-selection {
    position: relative;
    width: 440px;
    height: 40px;
    font-family: Inter;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 8px;
    padding-left: 8px;
    background: #f2f2f2 url("../../Assets/ContentLibrary/ContentLibrary/MainBar/dropdown.png") no-repeat 97% 50%;
    outline: none;
    border: none;
    cursor: pointer;
}

.add-content-selection option {
    font-family: "Inter";
}

.add-content-label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    margin-bottom: 10px;
}

.form-dropdown__left {
    margin-bottom: 20px;
}

.form-dropdown__left--err {
    margin-bottom: 10px;
}