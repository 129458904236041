.form-thumbnil {
    width: 440px;
    height: 104px;
    box-sizing: border-box;
    border: 1px dashed #2F80ED;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}

.form-thumbnil input[type="file"] {
    display: none;
}

.form-thumbnil__text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding-left: 10px;
    color: #CC435F;
    width: 50%;
}

.form-thumbnil__image {
    height: 80px;
    width: auto;
    margin-left: 25px;
}

.form-thumbnil__image-explainer-video {
    margin-left: 20px;
    width: 155px;
    height: 75px;
}