.login-username {
    width: 320px;
    border-radius: 10px;
    color: rgba(245, 247, 246, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #a6c8a6;
}

.login-username__field {
    border: none;
    width: inherit;
    padding: 5px;
    font-weight: 600;
    font-size: 14px;
    opacity: 0.7;
    outline: none;
    color: rgba(122, 122, 122, 1);
}