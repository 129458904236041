.chart-container__chart {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 250px;
    height: 100px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 10px rgba(220, 220, 220, 0.2);
    border-radius: 8px;
}

.chart-description {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100px;
    height: 100%;
}