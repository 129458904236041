.settings-form-checkbox {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    justify-content: center;
    flex-direction: column;
    height: 65px;
    width: 450px;
    margin-left: 10px;
    margin-top: 35px;
}

.settings-form-checkbox__label {
    position: relative;
    width: 449px;
    height: 34px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    background: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
}

.settings-form-checkbox-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 26px;
    color: #000000;
    margin-left: 10px;
}

.settings-form-checkbox-input {
    margin-left: 15px;
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.settings-form-checkbox__header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.settings-form-checkbox-description:before {
    content: '';
    appearance: inherit;
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #CC435F;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
}

.settings-form-checkbox__label input:checked+.settings-form-checkbox-description:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 18px;
    width: 5px;
    height: 15px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}