.suggestion-container {
    position: relative;
    height: 225px;
    width: calc(100% - 50px);
    margin-left: 24px;
    margin-top: 22px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgb(220 220 220 / 40%);
    border-radius: 16px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.suggestion-container__header {
    position: relative;
    height: 18%;
    width: 100%;
    background: linear-gradient(90deg, #6850EF 0%, #1887E5 100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.suggestion-header-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #F2F2F2;
    margin-left: 10px;
}

.suggestion-container__body {
    position: relative;
    width: 99%;
    height: 67%;
    display: flex;
    margin-top: 16px;
    margin-left: 10px;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: 10px;
}