.new-password-page {
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.new-password-page__form {
    height: 75%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.new-password-form-right {
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: no-repeat center center;
    background-position: center;
    background-size: contain;
}

.new-password-form-left {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.new-password-box {
    margin: 32px;
}

.box__heading {
    position: absolute;
    width: 146px;
    height: 30px;
    left: 98px;
    top: 192px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #3C3838;
}

.new-password-box-description {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
    width: 100%;
}