.header {
    height: 15%;
}

.header__logo {
    text-align: center;
    padding-top: 27px;
}

.header__line {
    height: 1px;
    width: 100%;
    border: 1px solid rgba(166, 200, 166, 1);
}