.modal__header-text {
    position: absolute;
    width: 35px;
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.modal__header-line {
    position: absolute;
    width: 340px;
    height: 0px;
    border: 1px solid #E0E0E0;
    margin-top: 30px;
}

.modal-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

div::-webkit-scrollbar {
    width: 0.5em;
    border-radius: 5px;
}

div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #f8fbff;
    border-radius: 100vw;
    margin-block: 0.5em;
}

div::-webkit-scrollbar-thumb {
    background-color: rgba(216, 216, 216, 1);
    border-radius: 100vw;
}

@supports (scrollbar-color: red blue) {

    /* For Firefox */
    * {
        /* scrollbar-color: thumbColor trackColor */
        scrollbar-color: #f8fbff rgba(216, 216, 216, 1);
        scrollbar-width: thin;
    }
}