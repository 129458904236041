.arl_table {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 1rem;
}

.arl_table-responsive {
  width: 98%;
  margin-left: -0.25%;
  height: 90%;
  overflow-y: auto;
}

.arl_table-display {
  width: 100%;
}

.arl_table-display thead {
  border-radius: 10px;
  border: 1px solid rgba(166, 200, 166, 0.25);
  background: #dceedc;
}

.arl_table-display thead tr {
  top: 0;
  height: 12%;
  width: 100%;
  position: sticky;
  background: #dceedc;
}

.arl_table-display tbody tr {
  background-color: #fff;
  border-radius: 8px;
  border-bottom: 1px solid grey;
  cursor: pointer;
}

.arl_table-display thead tr td {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.arl_table-display tbody tr td {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.arl-table-column-heading-text {
  width: max-content;
  height: 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4f4f4f;
  margin-right: 0.8em;
}

.arl_table-display thead tr td:nth-child(1),
.arl_table-display tbody tr td:nth-child(1) {
  width: 40%;
}

.arl_table-display thead tr td:nth-child(2),
.arl_table-display tbody tr td:nth-child(2) {
  width: 15%;
}

.arl_table-display thead tr td:nth-child(3),
.arl_table-display tbody tr td:nth-child(3) {
  width: 10%;
}

.arl_table-display thead tr td:nth-child(4),
.arl_table-display tbody tr td:nth-child(4) {
  width: 10%;
}

.arl_table-display thead tr td:nth-child(5),
.arl_table-display tbody tr td:nth-child(5) {
  width: 5%;
}
