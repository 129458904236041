.timeline-container {
    position: relative;
    height: auto;
    min-height: 200px;
    width: calc(100% - 50px);
    margin-left: 24px;
    margin-top: 22px;
    margin-bottom: 8px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgb(220 220 220 / 40%);
    border-radius: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
}

.timeline-container__header {
    position: relative;
    height: 8%;
    width: 100%;
    background: linear-gradient(90deg, #6850EF 0%, #1887E5 100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 45px;
}

.timeline-container__header-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #F2F2F2;
    margin-left: 10px;
}

.timeline-container__body {
    position: relative;
    width: 98%;
    height: 100%;
    overflow-y: auto;
}

.timeline-container__body-date-text {
    display: inline-block;
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-transform: uppercase;
    color: #4C4C4C;
    mix-blend-mode: normal;
    height: auto;
    width: auto;
    top: 4%;
    left: 3%;
}

.timeline-container__body-details-text {
    display: inline-block;
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    text-transform: uppercase;
    color: #4C4C4C;
    mix-blend-mode: normal;
    height: auto;
    width: auto;
    top: 4%;
    left: 10%;
}

.timeline-container__body-history-box-left {
    position: absolute;
    display: inline-block;
    width: auto;
    height: auto;
    min-width: 450px;
    min-height: 80px;
    top: 10%;
    left: 3%;
}

.timeline-container__body-history-box-right {
    position: absolute;
    display: inline-block;
    width: auto;
    height: auto;
    min-width: 450px;
    min-height: 80px;
    top: 20%;
    left: 49.5%;
}

.pointer-left {
    position: fixed;
    border: solid 10px transparent;
    border-right-color: #E1EBF5;
    position: absolute;
    margin: -50px 0 0 -20px;
}

.pointer-right {
    position: fixed;
    border: solid 10px transparent;
    border-left-color: #E1EBF5;
    position: absolute;
    margin: -50px 0 0 358px;
}