.playKit-holder {
    display: block;
    position: relative;
    margin-top: 2px;
    overflow-y: auto;
    width: 88%;
    height: 70%;
    left: 13%;
    max-height: 150px;
}

.playKit-holder__list {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}