.attachment-url {
    position: relative;
    width: 510px;
    height: 80px;
    border: none;
    outline: none;
    background: #FFFFFF;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
    border-radius: 16px;

    /* Input text style */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.attachment-url::placeholder {
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #CC435F;
    opacity: 1;
}