.toggle-box {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 53px;
    margin: 18px 0;
}

.toggle-box__label {
    position: relative;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
}