.attachment-file {
    position: relative;
    width: 510px;
    height: 80px;
    background: linear-gradient(90deg, #f25c7b 0%, #cc435f 100%);
    border-radius: 16px;
    cursor: pointer;
}

.attachment-file input[type="file"] {
    display: none;
}

.attachment-file__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
}

.attachment-file__text button {
    width: 56px;
    height: 25px;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: default;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.attachment-file__default {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}