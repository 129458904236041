.playKit-add-content {
  height: 100vh;
  width: calc(100% - 260px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playKit-add-content__header {
  position: sticky;
  top: 0px;
  height: 56px;
  width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
}

.playKit-header-img {
  display: inline-block;
  margin: 15px;
  vertical-align: middle;
  border-style: none;
}

.playKit-header-text {
  display: inline-block;
  margin: 15px 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #353131;
}

.playKit-add-content__form-container {
  position: relative;
  width: 100%;
  height: calc(100% - 57px);
  background: #eff3fd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playKit-add-content__form {
  position: relative;
  width: calc(100% - 56px);
  height: calc(100% - 36px);
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.playKit-text-holder {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 1%;
}

.playKit-tool-holder {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 73.5%;
  flex-direction: column;
}

.playKit-tool-holder__control {
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.playKit-tool-holder__text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
  color: #000000;
  width: fit-content;
}

.playKit-tool-holder__control-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  gap: 1%;
}

.playKit-tool-holder__control-btns__add {
  width: 45%;
  padding: 4px;
  border-radius: 6px;
  border: none;
  outline: none;
  background: #cc435f;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.playKit-tool-holder__control-btns__delete {
  width: 45%;
  padding: 4px;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  outline: none;
  background: #00000099;
  text-align: center;
  cursor: pointer;
}

.playKit-tool-holder__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 2px;
  max-height: 92%;
  width: 100%;
  overflow-y: auto;
}

.playKit-tool-holder__tool {
  position: relative;
  border: 0.5px solid rgb(193, 193, 193);
  border-radius: 8px;
  padding-bottom: 5px;
  max-height: 210px;
}

.playKit-tool-holder__tool-checkbox {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.playKit-tool-holder__tool-img {
  object-fit: cover;
  width: 100%;
  height: 140px;
  border-radius: 8px;
}

.playKit-tool-holder__tool-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.playKit-tool-holder__tool-container__name {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #4f4f4f;
}

.playKit-tool-holder__tool-container__controls {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.playKit-tool-holder__tool-container__description {
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  color: #4f4f4f;
  padding: 0 5px;
}

.playKit-form-submission {
  position: inherit;
  /* height: 80px; */
  width: 95%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2%;
}

.playKit-cancel-btn {
  width: 140px;
  height: 40px;
  border-radius: 6px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  border: 1px solid #a5a5a5;
  color: #333333;
}

.playKit-save-btn {
  width: 140px;
  height: 40px;
  background: #cc435f;
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}

.loading__spinners {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}
