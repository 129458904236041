.explainer-video-form__header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    height: auto;
}

.explainer-video-form__header-text {
    position: relative;
    width: auto;
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #353131;
}

.explainer-video-form__seperator {
    position: absolute;
    width: 340px;
    height: 0px;
    border: 1px solid #E0E0E0;
    margin-top: 5px;
}

.multi-select-checkbox-drpdwn,
.rmsc .dropdown-container {
    position: relative !important;
    width: 100% !important;
    height: 40px !important;
    background: #F2F2F2 !important;
    border-radius: 8px !important;
    font-family: "Inter" !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    /* background: #f2f2f2 url("../../Assets/ContentLibrary/ContentLibrary/MainBar/dropdown.png") no-repeat 96% 50% !important; */
    border: none !important;
    outline: none !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #4F4F4F !important;
}

.video-preview-btn {
    width: fit-content;
    height: auto;
    border: 1px solid #CC435F;
    background-color: #FFFFFF;
    color: #CC435F;
    font-size: 16px;
    font-family: "Inter";
    padding: 4px 8px;
    margin: 2px;
    /* border: none; */
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.video-preview-btn:hover {
    background-color: #CC435F;
    color: #FFFFFF;
}

.edit-explainer-video-preview-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
}