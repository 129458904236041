.filter {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  height: 100%;
  gap: 10px;
}

.filter__popup {
  height: 35px;
  width: 56px;
  /* margin-top: 30px; */
  background: #f2f2f2;
  border-radius: 16px;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}
