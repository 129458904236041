.explainer-videos-main-bar {
  height: 100vh;
  width: calc(100% - 260px);
  /* background: #EFF3FD; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.explainer-videos-main-bar__header {
  position: fixed;
  /* top: 0px;
  left: 19%; */
  /* height: 24px; */
  width: 100%;
  background: #ffffff;

  height: 5%;
  display: flex;
  float: left;
  align-self: flex-start;
}

.page-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #353131;
  margin: 5px 0;
}

.explainer-videos-main-bar__filter-component {
  /* height: 12%;
  width: 100%; */

  position: relative;
  width: 100%;
  top: 5%;
  height: 5%;
}

.explainer-videos-main-bar__table-component {
  /* height: 82%; */
  height: 88%;
  width: 100%;

  position: relative;
  top: 6%;
  background: #eff3fd;
}

.explainer-videos-main-bar__pagination-component {
  height: 6%;

  width: 100%;
  background: #eff3fd;
  position: relative;
  top: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}
