.display-activity {
    height: 100vh;
    width: calc(100% - 260px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #EFF3FD;
}

.display-activity__header {
    position: sticky;
    top: 0px;
    height: 56px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
}

.display-activity__main {
    position: relative;
    width: 100%;
    height: auto;
    overflow-y: scroll;
}