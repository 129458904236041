.form-right-text__input-suggestions {
    margin-top: -22px;
    max-height: 136px;
    width: 100%;
    z-index: 5;
    border: 1px solid #aaa;
    background: white;
    position: absolute;
    border-radius: 8px;
    overflow-y: auto;
    list-style: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    cursor: pointer;
    padding: 2px;
}

.text-input__suggestion--element {
    padding: 5px 8px;
}

.text-input__suggestion--element:hover {
    background: rgba(232, 232, 232, 1);
    border-radius: 6px;
}

.text-input__suggestion--element:focus {
    background: rgba(232, 232, 232, 1);
    border-radius: 6px;
    border: none;
    outline: none;
}

.text-input__field::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
}

.text-input__suggestions--modal {
    margin-top: -22px;
    max-height: 136px;
    width: 88%;
    z-index: 5;
    border: 1px solid #aaa;
    background: white;
    position: absolute;
    border-radius: 8px;
    overflow-y: auto;
    list-style: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    cursor: pointer;
    padding: 2px;
}