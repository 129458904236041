.activity-stack-modal__header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    height: auto;
}

.activity-stack-header-text {
    position: relative;
    width: 35px;
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.activity-stack-modal__seperator {
    position: absolute;
    width: 340px;
    height: 0px;
    border: 1px solid #E0E0E0;
    margin-top: 10px;
}

.activity-stack-modal__checkbox-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 20px;
}