.formik {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 12px;
}

.formik-input {
    display: flex;
    width: 60%;
    padding: 5%;
    flex-direction: column;
}

.formik-error {
    width: 340px;
    color: red;
    margin-bottom: 10px;
    padding-left: 5px;
    text-align: start;
}

.add-content-form-error {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #F2994A;
    margin-top: -15px;
    margin-bottom: 20px;
}

::-webkit-scrollbar {
    width: 0.2em;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #f8fbff;
    border-radius: 100vw;
    margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(216, 216, 216, 1);
    border-radius: 100vw;
}

@supports (scrollbar-color: red blue) {

    /* For Firefox */
    * {
        /* scrollbar-color: thumbColor trackColor */
        scrollbar-color: #f8fbff rgba(216, 216, 216, 1);
        scrollbar-width: thin;
    }
}