.action-button {
    width: 340px;
    background: rgba(204, 67, 95, 1);
    border: none;
    outline: none;
    border-radius: 10px;
    color: rgba(246, 248, 247, 1);
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}