.table-control {
  width: 100%;
  height: 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.table-control__buttons {
  width: 30%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.table-control__buttons--with-modal {
  width: 28%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 71.5%;
}

.table-control__count {
  width: 10%;
  height: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  margin-left: 1%;
}

.add-content-btn {
  width: 140px;
  height: 30px;
  background: #cc435f;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
}

.add-content-btn-text {
  width: 102px;
  height: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin: auto;
  text-align: center;
}

.remove-content-btn {
  width: 140px;
  height: 30px;
  background: #bdbdbd;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
}

.remove-content-btn-text {
  width: 104px;
  height: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  margin: auto;
  text-align: center;
}
