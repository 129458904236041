.question-timer-modal__header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  height: auto;
}

.question-timer-modal__header-text {
  position: relative;
  width: auto;
  height: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #353131;
}

.question-timer-modal__seperator {
  position: absolute;
  width: 340px;
  height: 0px;
  border: 1px solid #e0e0e0;
  margin-top: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: "textfield";
  -moz-appearance: textfield;
}

.questionTimerDetails-placeholder::placeholder {
  text-align: center;
}
