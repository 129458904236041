.page-footer {
    color: rgba(166, 166, 166, 1);
    height: 10%;
    background: rgba(245, 247, 246, 1);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    font-weight: 600;
    font-size: 13px;
}