.stats-detail {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 30px;
    width: 100px;
    background: linear-gradient(90deg, #F25C7B 0%, #CC435F 100%);
    border-radius: 6px;
}

.stats-detail__count {
    position: relative;
    height: 15px;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #F2F2F2;
}