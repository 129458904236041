.school-details {
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.school-details__name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin-top: 16px;
    line-height: 22px;
    color: #2E2B2B;
}

.school-details__address {
    height: 100%;
    width: 80%;
    margin-top: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #7B7B7B;
}

.school-details__image {
    height: 80px;
    width: auto;
}