.display-content {
    height: 100vh;
    width: calc(100% - 260px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #EFF3FD;
}

.display-content__header {
    position: sticky;
    top: 0px;
    height: 56px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
}

.display-content__main {
    position: relative;
    width: 100%;
    height: auto;
    overflow-y: scroll;
}

.header-img {
    display: inline-block;
    margin: 15px;
    vertical-align: middle;
    border-style: none;
}

.header-text {
    display: inline-block;
    margin: 15px 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #353131;
}