.display-content {
    position: relative;
    width: 100%;
    height: calc(100% - 66px);
}

.display-content__menu-preview {
    position: relative;
    top: 24px;
    width: calc(100% - 50px);
    height: 450px;
    margin-left: 24px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 10px rgba(220, 220, 220, 0.2);
    border-radius: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.content-thumbnail__holder {
    position: relative;
    width: 100%;
    max-width: 610px;
}

.content-thumbnail {
    position: relative;
    width: 610px;
    height: 420px;
    filter: drop-shadow(0px 0px 20px #D8D4EE);
}

.thumbnail-image {
    position: relative;
    width: 400px;
    height: 400px;
    margin-left: 60px;
}

.preview-details {
    display: flex;
    flex-direction: column;
    height: 380px;
    width: 540px;
    margin-right: 6px;
}

.id-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-id {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-left: 10px;
    color: #333333;
}

.content-edit-btn {
    position: relative;
    height: 29px;
    width: 128px;
    background: #2F80ED;
    border-radius: 6px;
    border: none;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    margin-right: 5px;
    cursor: pointer;
}

.display-stats {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 5px;
    justify-content: space-between;
}

.display-stats--activity-stack {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 5px;
    justify-content: flex-start;
    gap: 20px;
}

.stats__count_curated {
    position: relative;
    height: 15px;
    width: 47px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #F2F2F2;
}

.stats-partition {
    position: relative;
    margin-top: 5px;
    margin-left: 12px;
    height: 1px;
    width: 95%;
    border-radius: 0px;
    border: 1px solid #E0E0E0;
}

.display-name {
    position: relative;
    height: 60px;
    width: 95%;
    margin: 5px 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #CC435F;
    overflow-y: auto;
}

.user-details {
    display: flex;
    width: 60%;
    height: 25px;
    justify-content: flex-start;
    align-items: center;
    margin-left: 8px;
}

.profile-pic {
    position: relative;
    height: 25px;
    width: 25px;
    border: 1.5px solid #FFFFFF;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    margin-right: 12px;
}

.user-name {
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4F4F4F;
}

.content-description {
    position: relative;
    height: 155px;
    width: 95%;
    margin: 5px 0 0 10px;
    border-radius: nullpx;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #4F4F4F;
    overflow-y: auto;
}