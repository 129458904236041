.secondary-card {
    position: relative;
    height: 100px;
    width: 240px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(220, 220, 220, 0.4);
    border-radius: 16px;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.secondary-card__description {
    position: relative;
    height: 50%;
    width: 100%;
    background: linear-gradient(90deg, #6850EF 0%, #1887E5 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondary-card-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #F2F2F2;
}

.secondary-card__value {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4F4F4F;
    overflow: auto;
}