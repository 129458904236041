.activity-stack__table {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.activity-stack__table-display {
  display: flex;
  width: 98%;
  margin-left: -0.25%;
  align-items: center;
  flex-direction: column;
  border-collapse: collapse;
  min-width: 400px;
  max-height: 362px;
}

.activity-stack__table-display thead {
  display: flex;
  align-items: center;
  top: 0;
  height: 40px;
  width: 100%;
  position: sticky;
  border-radius: 8px;
  background: #dceedc;
}

.activity-stack__table-display thead tr {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
}

.activity-stack__table-display thead tr th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #4f4f4f;
  height: fit-content;
  padding: 5px;
}

.activity-stack__table-display tbody {
  display: flex;
  width: 100%;
  min-height: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  margin-top: 4px;
}

.activity-stack__table-display tbody tr {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  margin: 5px 0;
  transition: transform 0.2s;
}

.activity-stack__table-display tbody tr:hover {
  cursor: pointer;
  transform: scale(1.0095);
}

.activity-stack__table-display tbody td {
  padding: 2px;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  margin: 3px 0;
  min-height: 35px;
  display: flex;
  align-items: center;
}

.activity-stack__table-display__row--data__has-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
}

.activity-stack__table-display__header__text {
  width: max-content;
  height: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4f4f4f;
  margin-right: 0.8em;
}

.activity-stack__table-display thead tr th:nth-child(1),
.activity-stack__table-display tbody tr td:nth-child(1) {
  width: 2%;
}

.activity-stack__table-display tbody tr td:nth-child(1) {
  margin-left: 5px;
}

.activity-stack__table-display thead tr th:nth-child(2) {
  width: 24%;
}

.activity-stack__table-display tbody tr td:nth-child(2) {
  width: 23%;
}

.activity-stack__table-display thead tr th:nth-child(3) {
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity-stack__table-display tbody tr td:nth-child(3) {
  width: 14%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-stack__table-display thead tr th:nth-child(4) {
  position: relative;
  left: 16px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity-stack__table-display tbody tr td:nth-child(4) {
  position: relative;
  left: 10px;
  margin-left: 45px;
}

.activity-stack__table-display thead tr th:nth-child(5) {
  width: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity-stack__table-display tbody tr td:nth-child(5) {
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity-stack__table-display tbody tr td:nth-child(6) {
  margin-left: 60px;
}

.activity-stack__table-display thead tr th:nth-child(7) {
  margin-left: 10px;
}

.activity-stack__table-display tbody tr td:nth-child(7) {
  margin-left: 68px;
}

.activity-stack__table-display tbody tr td:nth-child(8) {
  margin-left: 50px;
}

.activity-stack__table-display tbody tr td:nth-child(9) {
  margin-left: 33px;
}

.activity-stack__table-display thead tr th:nth-child(10),
.activity-stack__table-display thead tr th:nth-child(11),
.activity-stack__table-display thead tr th:nth-child(12) {
  margin-left: 18px;
}

.activity-stack__table-display tbody tr td:nth-child(10) {
  margin-left: 30px;
}

.activity-stack__table-display tbody tr td:nth-child(11) {
  margin-left: 30px;
}

.activity-stack__table-display tbody tr td:nth-child(12) {
  margin-left: 30px;
}

.table-responsive {
  width: 98%;
  margin-left: -0.25%;
}

.activity-table-display thead {
  border-radius: 10px;
  border: 1px solid rgba(166, 200, 166, 0.25);
  background: #dceedc;
  width: 100%;
}

.activity-table-display thead tr {
  top: 0;
  height: 12%;
  width: 100%;
  position: sticky;
  background: #dceedc;
  /* border: 1px solid; */
}

.activity-table-display tbody tr {
  background-color: #fff;
  border-radius: 8px;
  border-bottom: 1px solid grey;
  cursor: pointer;
}

.activity-table-display thead tr td {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.activity-table-display tbody tr td {
  /* text-align: left; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.activity-table-display thead tr td:nth-child(3),
.activity-table-display tbody tr td:nth-child(3) {
  width: 120%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-table-display thead tr td:nth-child(5),
.activity-table-display tbody tr td:nth-child(5) {
  width: 11%;
}

.activity-table-display tbody tr td:nth-child(4),
.activity-table-display tbody tr td:nth-child(6),
.activity-table-display tbody tr td:nth-child(7),
.activity-table-display tbody tr td:nth-child(8),
.activity-table-display tbody tr td:nth-child(9),
.activity-table-display tbody tr td:nth-child(10),
.activity-table-display tbody tr td:nth-child(11),
.activity-table-display tbody tr td:nth-child(12) {
  text-align: center;
}
