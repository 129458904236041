.container {
    width: 80%;
    margin-top: 50px;
}

.container__element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #5aab00;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-content: center;
}

.container__element-text {
    padding-top: 0%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #454040;
}

.container__element img {
    vertical-align: middle;
    border-style: none;
}

.container__element__message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #59BC4B;
}