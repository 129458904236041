.checkbox-element {
    display: flex;
    justify-content: flex-start;
    margin: 10px;
    cursor: pointer;
}

.checkbox-element__label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    margin-left: 10px;
}