.edit-history-box__seperator {
    display: inline-block;
    position: absolute;
    height: 100%;
    left: 10%;
    top: 0;
    mix-blend-mode: normal;
    opacity: 0.8;
    border-left: 1px dashed #979797;
    min-height: 50px;
}

.edit-history-box__date-box {
    display: flex;
    position: absolute;
    height: 30px;
    width: 52px;
    top: 9%;
    left: -3%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.edit-history-box__date-day {
    position: relative;
    height: 15px;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #4F4F4F;
    mix-blend-mode: normal;
    opacity: 0.8;
}

.edit-history-box__month-year {
    position: relative;
    height: 12px;
    width: 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #4F4F4F;
    mix-blend-mode: normal;
    opacity: 0.8;
}

.edit-history-box__history {
    position: absolute;
    top: 0%;
    left: 12.5%;
    background: #E1EBF5;
    border-radius: 8px;
}

.edit-history-box__history-type {
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 350px;
    height: 40px;
    margin-bottom: 2px;
}

.edit-history-box__history-type-description {
    position: relative;
    display: inline;
    margin: 6px 0 0 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #292929;
}

.edit-history-box__history-type-description-creator {
    position: relative;
    display: inline;
    margin: 0 0 0 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #292929;
}

.edit-history-box__history-comment-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #767676;
    margin: 2px 0 8px 10px;
    min-height: 15px;
    max-height: 35px;
    overflow-y: auto;
}