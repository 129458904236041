.login-page {
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.login__form {
    height: 75%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.login-form-right {
    height: 90%;
    width: 100%;
    background: no-repeat center center;
    background-position: center;
    background-size: contain;
}

.login-form-left {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.forgotPassword {
    padding-top: 20px;
    font-size: 13px;
}

a.forgotPassword {
    color: rgba(145, 145, 145, 1);
    padding-top: 20px;
}

a.forgotPassword:hover {
    color: rgba(204, 67, 95, 1);
}