.modules-item {
    width: 220px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
    display: block;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
}

.modules-item__details-img {
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 20px;
}

.modules-item__details-div {
    display: inline-block;
    margin-left: 20px;
}

.modules-item--clicked {
    width: 220px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    background: rgba(232, 232, 232, 1);
    color: rgba(204, 67, 95, 1);
    display: block;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
}