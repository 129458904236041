.sort__arrows {
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: space-around;
  height: 20px;
}

.content-table {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.content-table__display {
  display: flex;
  width: 98%;
  margin-left: -0.25%;
  align-items: center;
  flex-direction: column;
  border-collapse: collapse;
  /* min-width: 400px;
    max-height: 362px; */

  height: 100%;
}

.content-table__display thead {
  display: flex;
  align-items: center;
  top: 0;
  height: 12%;
  width: 100%;
  position: sticky;
  border-radius: 8px;
  background: #dceedc;
}

.content-table__display thead tr {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
}

.content-table__display thead tr th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #4f4f4f;
  height: fit-content;
  padding: 5px;
}

.content-table__display tbody {
  display: flex;
  width: 100%;
  /* height: 310px; */
  height: 88%;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  /* margin-top: 4px; */
}

.content-table__display tbody tr {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  margin: 5px 0;
  transition: transform 0.2s;
}

.content-table__display tbody tr:hover {
  cursor: pointer;
  transform: scale(1.0095);
}

.content-table__display tbody td {
  padding: 2px;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  margin: 3px 0;
  min-height: 35px;
  display: flex;
  align-items: center;
}

.row-data-has-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
}

.row-data-header-text {
  width: max-content;
  height: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4f4f4f;
  margin-right: 0.8em;
}

.content-table__display thead tr th:nth-child(1),
.content-table__display tbody tr td:nth-child(1) {
  width: 2%;
}

.content-table__display tbody tr td:nth-child(1) {
  margin-left: 5px;
}

.content-table__display thead tr th:nth-child(2) {
  width: 24%;
}

.content-table__display tbody tr td:nth-child(2) {
  width: 25%;
}

.content-table__display thead tr th:nth-child(3) {
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-table__display tbody tr td:nth-child(3) {
  width: 14%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-table__display thead tr th:nth-child(4) {
  position: relative;
  left: 16px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-table__display tbody tr td:nth-child(4) {
  position: relative;
  left: 10px;
  margin-left: 12px;
  width: 6%;
}

.content-table__display thead tr th:nth-child(5) {
  width: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 2%;
}

.content-table__display tbody tr td:nth-child(5) {
  margin-left: 36px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8%;
}

.content-table__display thead tr th:nth-child(6) {
  margin-left: 18px;
}

.content-table__display tbody tr td:nth-child(6) {
  margin-left: 10px;
  width: 5%;
  display: flex;
  justify-content: right;
}

.content-table__display thead tr th:nth-child(7) {
  margin-left: 10px;
}

.content-table__display tbody tr td:nth-child(7) {
  margin-left: 78px;
}

.content-table__display thead tr th:nth-child(8) {
  margin-left: 2px;
}

.content-table__display tbody tr td:nth-child(8) {
  margin-left: 50px;
}

.content-table__display thead tr th:nth-child(9) {
  margin-left: 2px;
}

.content-table__display tbody tr td:nth-child(9) {
  margin-left: 28px;
}

.content-table__display thead tr th:nth-child(10) {
  margin-left: 18px;
}

.content-table__display thead tr th:nth-child(11) {
  margin-left: 23px;
}

.content-table__display tbody tr td:nth-child(10) {
  margin-left: 22px;
  width: 2.5%;
  line-break: anywhere;
}

.content-table__display tbody tr td:nth-child(11) {
  margin-left: 18px;
  width: 3.5%;
  line-break: anywhere;
}

.no-data-text {
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #333333;
}

.no-data-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-responsive {
  width: 98%;
  margin-left: -0.25%;
}

.content-table-display thead {
  border-radius: 10px;
  border: 1px solid rgba(166, 200, 166, 0.25);
  background: #dceedc;
}

.content-table-display thead tr {
  top: 0;
  height: 12%;
  width: 100%;
  position: sticky;
  background: #dceedc;
  /* border: 1px solid; */
}

.content-table-display tbody tr {
  background-color: #fff;
  border-radius: 8px;
  border-bottom: 1px solid grey;
  cursor: pointer;
}

.content-table-display thead tr td {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.content-table-display tbody tr td {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.content-table-display tbody tr td:nth-child(6),
.content-table-display tbody tr td:nth-child(7),
.content-table-display tbody tr td:nth-child(8) {
  text-align: center;
}
