.main-bar {
  height: 100vh;
  width: calc(100% - 260px);
  /* background: #EFF3FD; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-bar__header {
  position: fixed;
  /* top: 0px;
    left: 19%; */
  height: 5%;
  width: 100%;
  background: #ffffff;
  display: flex;
  float: left;
  align-self: flex-start;
}

.page-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #353131;
  /* margin: 5px 0; */
}

.main-bar__filter-component {
  /* height: 12%;
    width: 100%; */

  position: relative;
  width: 100%;
  top: 5%;
  height: 5%;
}

.main-bar__stats-component {
  height: 18%;
  width: 100%;

  top: 6%;
  position: relative;
  background: #eff3fd;
}

.main-bar__table-component {
  position: relative;
  top: 6%;
  /* height: 65%; */
  height: 71%;
  width: 100%;
  background: #eff3fd;
}

.main-bar__pagination-component {
  /* height: 2%;
    width: 100%;
    background: #EFF3FD; */

  height: 6%;
  width: 100%;
  background: #eff3fd;
  position: relative;
  top: 6%;
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}
