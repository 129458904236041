.reset-password-page {
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;
}

.reset-password-page__form {
  height: 75%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.reset-password-form-right {
  height: 70%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: no-repeat center center;
  background-position: center;
  background-size: contain;
}

.reset-password-form-left {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-content: center; */
  /* align-items: center; */
  flex-direction: column;
  padding-left: 100px;
  padding-right: 100px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

#reset-password-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  opacity: 0.7;
  line-height: 21px;
}
.reset-password__box {
  margin-bottom: 32px;
}

.reset-password-box-description {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
  width: 86%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4e4949;
}
