.suggestion-box {
    position: relative;
    height: 142px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 10px rgba(220, 220, 220, 0.2);
    border-radius: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 0 0 32.3333%;
}

.suggestion-box__suggestor-detail {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 12px;
}

.suggestor-name {
    position: relative;
    width: 100%;
    height: 15%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #2F80ED;
}

.suggestor-message {
    position: relative;
    width: 100%;
    height: 70%;
    margin-top: 3px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #4F4F4F;
    overflow-y: auto;
}

.suggestor-date {
    position: relative;
    width: 90%;
    height: 15%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #2F80ED;
}

.suggestion-box__delete-btn {
    width: 10%;
}