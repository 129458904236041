.form-right-text__label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
}

.form-right-text-input {
    border-style: none none solid none;
    border-color: #E0E0E0;
}

.form-right-text__input-field {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    color: #333333;
    margin: 10px 0;
    border: none;
    outline: none;
}