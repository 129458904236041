.display-cards {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.display-cards__primary {
    position: relative;
    width: 96%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.display-cards__secondary {
    position: relative;
    width: 96%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;
}

.display-cards__tertiary {
    position: relative;
    width: 96%;
    height: 96px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(220, 220, 220, 0.4);
    border-radius: 16px;
    margin-top: 22px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.activity-details-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    height: auto;
    max-height: 200px;
    margin-top: 18px;
}

.activity-details-container__box1 {
    display: flex;
    position: relative;
    width: 512px;
    height: auto;
    min-height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(220, 220, 220, 0.4);
    border-radius: 16px;
    justify-content: space-evenly;
}

.activity-description {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 35%;
    height: auto;
    max-height: 200px;
}

.activity-section-seperator {
    display: inline-block;
    position: absolute;
    height: 100%;
    left: 10%;
    top: 0;
    mix-blend-mode: normal;
    opacity: 0.8;
    border-left: 1px solid #E0E0E0;
    min-height: 50px;
    margin-left: 30%;
}

.activity-playkit-details {
    position: relative;
    width: 55%;
    max-height: 200px;
}