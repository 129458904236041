.tertiary-card {
    position: relative;
    height: 48px;
    width: 317px;
    background: linear-gradient(90.07deg, #21967D 3.65%, #0D90AD 99.96%);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tertiary-card__value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #F2F2F2;
}