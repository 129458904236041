.playKit-main-bar {
  height: 100vh;
  width: calc(100% - 260px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playKit-main-bar__header {
  position: fixed;
  width: calc(100% - 260px);
  background: #ffffff;
  height: 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.playKit-main-bar__table-component {
  height: 94%;
  width: 100%;
  position: relative;
  top: 6%;
  background: #eff3fd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plakit-no-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #ffffff;
  border-radius: 10px;
}

.plakit-no-data__text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  height: 30px;
}

.playkit-add-btn {
  background: #cc435f;
  color: #ffffff;
  min-width: 184px;
  height: 32px;
  border: none;
  outline: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
