.activity-video-holder {
    display: flex;
    position: relative;
    width: 512px;
    height: auto;
    min-height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(220, 220, 220, 0.4);
    border-radius: 16px;
    justify-content: space-evenly;
    align-items: center;
}

.activity-video-holder__detail {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    height: auto;
    max-height: 200px;
    gap: 5px;
    overflow-y: auto;
}

.activity-video-holder__container {
    position: relative;
    width: 100%;
    max-width: 220px;
}

.activity-video-holder__thumbnail {
    width: 220px;
    height: 160px;
    border-radius: 16px;
}

.preview-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    color: #CC435F;
    font-size: 16px;
    font-family: "Inter";
    padding: 9px 18px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.preview-btn:hover {
    background-color: #CC435F;
    color: #FFFFFF;
}