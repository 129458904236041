.explainer-video-table {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.explainer-video-table__display {
  display: flex;
  width: 98%;
  margin-left: -0.25%;
  align-items: center;
  flex-direction: column;
  border-collapse: collapse;
  min-width: 400px;
  max-height: 460px;
}

.explainer-video-table__display thead {
  display: flex;
  align-items: center;
  top: 0;
  height: 40px;
  width: 100%;
  position: sticky;
  border-radius: 8px;
  background: #dceedc;
}

.explainer-video-table__display thead tr {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
}

.explainer-video-table__display thead tr th {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #4f4f4f;
  height: fit-content;
  padding: 5px;
}

.explainer-video-table__display tbody {
  display: flex;
  width: 100%;
  min-height: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  margin-top: 4px;
}

.explainer-video-table__display tbody tr {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  margin: 5px 0;
  transition: transform 0.2s;
}

.explainer-video-table__display tbody tr:hover {
  cursor: pointer;
  transform: scale(1.0095);
}

.explainer-video-table__display tbody td {
  padding: 2px;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  margin: 3px 0;
  min-height: 35px;
  display: flex;
  align-items: center;
}

.explainer-video-table-column-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
}

.explainer-video-table-column-heading-text {
  width: max-content;
  height: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4f4f4f;
  margin-right: 0.8em;
}

.explainer-video-table__display thead tr th:nth-child(1),
.explainer-video-table__display tbody tr td:nth-child(1) {
  width: 2%;
}

.explainer-video-table__display thead tr th:nth-child(2),
.explainer-video-table__display tbody tr td:nth-child(2) {
  width: 4%;
}

.explainer-video-table__display tbody tr td:nth-child(2) {
  margin-left: 1%;
}

.explainer-video-table__display thead tr th:nth-child(3),
.explainer-video-table__display tbody tr td:nth-child(3) {
  width: 24%;
  display: flex;
  align-items: center;
}

.explainer-video-table__display thead tr th:nth-child(3) {
  justify-content: center;
}

.explainer-video-table__title-thumbnail {
  display: block;
  position: relative;
  margin-right: 20px;
  width: 34px;
  height: 24px;
}

.explainer-video-table__display thead tr th:nth-child(4),
.explainer-video-table__display tbody tr td:nth-child(4) {
  width: 11%;
  display: flex;
  align-items: center;
}

.explainer-video-table__display thead tr th:nth-child(4) {
  margin-left: 8px;
}

.explainer-video-table__display thead tr th:nth-child(5),
.explainer-video-table__display tbody tr td:nth-child(5) {
  width: 10%;
  display: flex;
  justify-content: center;
}

.explainer-video-table__display thead tr th:nth-child(5) {
  justify-content: flex-start;
}

.explainer-video-table__display thead tr th:nth-child(6),
.explainer-video-table__display tbody tr td:nth-child(6) {
  width: 11%;
  display: flex;
  justify-content: center;
}

.explainer-video-table__display thead tr th:nth-child(6) {
  justify-content: flex-start;
}

.explainer-video-table__display thead tr th:nth-child(7),
.explainer-video-table__display tbody tr td:nth-child(7) {
  width: 8%;
  display: flex;
  justify-content: center;
}

.explainer-video-table__display thead tr th:nth-child(7) {
  position: relative;
  width: 5%;
  margin-left: -20px;
  justify-content: flex-start;
}

.explainer-video-table__display tbody tr td:nth-child(8) {
  width: 10%;
  display: flex;
  justify-content: center;
}

.explainer-video-table__display thead tr th:nth-child(8) {
  position: relative;
  width: auto;
  margin-left: 35px;
}

.explainer-video-table__display tbody tr td:nth-child(9) {
  position: relative;
  left: 2%;
  width: 5%;
  display: flex;
  justify-content: center;
  margin-left: -10px;
}

.explainer-video-table__display thead tr th:nth-child(9) {
  position: relative;
  width: auto;
  margin-left: 25px;
}

.explainer-video-table__display tbody tr td:nth-child(10) {
  position: relative;
  left: 4.5%;
  width: 5%;
  display: flex;
  justify-content: center;
}

.explainer-video-table__display thead tr th:nth-child(10) {
  position: relative;
  width: auto;
  margin-left: 8px;
}

.explainer-video-table__no-data {
  transition: transform 0.2s;
}

.explainer-video-table__no-data td {
  width: 2%;
}

.table-responsive {
  width: 98%;
  margin-left: -0.25%;
}

.explainer-video-table-display thead {
  border-radius: 10px;
  border: 1px solid rgba(166, 200, 166, 0.25);
  background: #dceedc;
}

.explainer-video-table-display thead tr {
  top: 0;
  height: 12%;
  width: 100%;
  position: sticky;
  background: #dceedc;
  /* border: 1px solid; */
}

.explainer-video-table-display tbody tr {
  background-color: #fff;
  border-radius: 8px;
  border-bottom: 1px solid grey;
  cursor: pointer;
}

.explainer-video-table-display thead tr td {
  /* text-align: left; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.explainer-video-table-display tbody tr td {
  /* text-align: left; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
  padding: 15px;
}

.explainer-video-table-display thead tr td:nth-child(3) {
  text-align: center;
}

.explainer-video-table-display tbody tr td:nth-child(3) {
  width: 115%;
  display: flex;
  align-items: center;
}

.explainer-video-table-display tbody tr td:nth-child(6),
.explainer-video-table-display tbody tr td:nth-child(7),
.explainer-video-table-display tbody tr td:nth-child(8) {
  text-align: left;
}

.explainer-video-table-display tbody tr td:nth-child(9),
.explainer-video-table-display tbody tr td:nth-child(10) {
  text-align: center;
}
