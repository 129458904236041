.login-password {
    width: 320px;
    border-radius: 10px;
    color: rgba(245, 247, 246, 1);
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #a6c8a6;
    justify-content: space-between;
}

.login-password__field {
    border: none;
    padding: 5px;
    font-weight: 600;
    font-size: 14px;
    opacity: 0.7;
    outline: none;
    color: rgba(122, 122, 122, 1);
}

.login-password__show {
    font-size: 11px;
    opacity: 0.5;
    color: #000;
    cursor: pointer;
}