.add-tool-form__header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  height: auto;
}

.add-tool-form__header-text {
  position: relative;
  width: auto;
  height: 17px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #353131;
}

.add-tool-form {
  display: flex;
  flex-direction: column;
  align-items: normal;
  gap: 12px;
  height: auto;
}

.add-tool-form__add-img-holder {
  position: relative;
  border-radius: 8px;
  border: 1px solid #000000de;
}

.add-tool-form__add-img-btn {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 10px 0;
}

.add-tool-form__add-thumbnail-holder {
  position: relative;
  /* max-height: 200px; */
  max-width: 300px;
  border: 1px solid;
  height: 200px;
}

.add-tool-form__add-thumbnail-img {
  object-fit: inherit;
  top: 0;
  width: 100%;
  height: 200px;
}

.add-tool-form__add-thumbnail-btns {
  position: absolute;
  bottom: 1%;
  right: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-tool-form__add-thumbnail-btns__add {
  margin-right: 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  background: #1565c0;
  border: 1px solid #1565c0;
  color: #fff;
  position: relative;
  cursor: pointer;
}

.add-tool-form__add-thumbnail-btns__remove {
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  border: 1px solid #c62828;
  border: none;
  outline: none;
  background: #ef5350;
  color: #fff;
  cursor: pointer;
}

.add-tool-form__add-thumbnail-input {
  position: absolute;
  opacity: 0;
  top: 0%;
  height: 100%;
  cursor: pointer;
  width: 100%;
  left: 0;
}
