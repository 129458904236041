.search-bar {
    position: relative;
    /* width: 150px;
    height: 35px; */
    width: 15%;
    height: 100%;
    padding-left: 40px;
    padding-right: 10px;
    /* margin-top: 30px; */
    /* margin-right: 12px; */
    border-radius: 16px;
    font-family: "Poppins";
    background: #F2F2F2 url("../../Assets/ContentLibrary/ContentLibrary/MainBar/search-shape.png") no-repeat 10% 50%;
    outline: none;
    border: none;
}

.playKit-searchBar {
    position: relative;
    width: 25%;
    height: 70%;
    margin-right: 25px;
    border-radius: 16px;
    padding-left: 60px;
    padding-right: 10px;
    font-family: "Poppins";
    background: #F2F2F2 url("../../Assets/ContentLibrary/ContentLibrary/MainBar/search-shape.png") no-repeat 10% 50%;
    outline: none;
    border: none;
}