.add-content {
    height: 100vh;
    width: calc(100% - 260px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-content__header {
    position: sticky;
    top: 0px;
    height: 56px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
}

.header-img {
    display: inline-block;
    margin: 15px;
    vertical-align: middle;
    border-style: none;
}

.header-text {
    display: inline-block;
    margin: 15px 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #353131;
}

.add-content__form {
    position: relative;
    width: 100%;
    height: calc(100% - 56px);
    background: #EFF3FD;
    overflow-y: scroll;
}

.form-attachment {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
}

.form-inputs {
    position: relative;
    top: 0px;
    width: calc(100% - 50px);
    height: auto;
    margin-left: 24px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 10px rgba(220, 220, 220, 0.2);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.form-left {
    position: relative;
    width: 402px;
    margin-top: 25px;
}

.form-right {
    position: relative;
    margin-top: 25px;
    margin-left: 97px;
}

.form-dropdowns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 440px;
    margin-top: 71px;
}

.form-right-text {
    margin-top: 32px;
    margin-bottom: 25px;
}

.form-right-radio {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.form-radio-category {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    margin-bottom: 16px;
}

.form-submission {
    position: inherit;
    /* width: auto; */
    height: 80px;
    width: 100%;
    /* margin-top: 37px; */
    background: #FFFFFF;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cancel-btn {
    width: 140px;
    height: 40px;
    margin-left: 25px;
    border: 1px solid #BDBDBD;
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #BDBDBD;
    cursor: pointer;
    border: none;
    outline: none;
}

.save-btn {
    width: 140px;
    height: 40px;
    margin-right: 25px;
    background: #CC435F;
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
}

.loading__spinners {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.content-question-time-btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    background: #f06f89;
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    margin-bottom: 44px;
}