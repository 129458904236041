.dropdown {
    position: relative;
    /* width: 148px;
    height: 35px; */
    width: 14%;
    height: 100%;
    /* margin-top: 30px; */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 16px;
    /* margin-right: 18px; */
    padding-left: 8px;
    background: #f2f2f2 url("../../Assets/ContentLibrary/ContentLibrary/MainBar/dropdown.png") no-repeat 90% 50%;
    outline: none;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
}

.dropdown option {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
    border: none;
    outline: none;
    border-radius: 5px;
}

.dropdown-modal {
    position: relative;
    width: 100%;
    height: 40px;
    background: #F2F2F2;
    border-radius: 8px;
    margin-top: 70px;
    font-family: "Inter";
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-right: 18px;
    padding-left: 16px;
    background: #f2f2f2 url("../../Assets/ContentLibrary/ContentLibrary/MainBar/dropdown.png") no-repeat 96% 50%;
    border: none;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
}