.form-radio {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.form-radio__button {
    position: relative;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.button-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.button-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #4F4F4F;
    margin-left: 15px;
}