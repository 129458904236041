.upload-content-main-page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  overflow: hidden;
}

.upload-content-main-page__bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}