.playKit-input-holder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.playKit-text-input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: none;
  outline: none;
  background: #f2f2f2;
  padding: 10px;
}

.playKit-input-holder__label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
}

.playKit-input-err-char__holder {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.playKit-input-holder__char {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.94px;
  opacity: 0.8;
  align-self: flex-end;
}

.playKit-input-holder__err {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  color: #f2994a;
}
