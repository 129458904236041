.chart-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.chart-container__multimedia-info {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* width: 300px;
    height: 96px; */
    width: 25%;
    height: 85%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 10px rgba(220, 220, 220, 0.2);
    border-radius: 16px;
}

.chart-container__readable-info {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* width: 300px;
    height: 96px; */
    width: 25%;
    height: 85%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 10px rgba(220, 220, 220, 0.2);
    border-radius: 16px;
}

.chart-container__stats-display {
    position: relative;
    /* width: 422px;
    height: 96px; */
    width: 40%;
    height: 85%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 10px rgba(220, 220, 220, 0.2);
    border-radius: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.multimedia-info-count {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    width: 100%;
    height: auto;
}

.readable-info-count {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    width: 100%;
    height: auto;
}

.count-display {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* margin-left: 10%; */
}

.stats-display-name {
    position: relative;
    /* width: 90px; */
    width: auto;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2F80ED;
}

.stats-display-number {
    position: relative;
    width: 19px;
    height: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    /* font-size: 12px; */
    font-size: 100%;
    line-height: 15px;
    color: #2D9CDB;
}

.stats-display-header {
    position: relative;
    width: 38px;
    height: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2F80ED;
    margin: 5px 5px 2px 6px;
}

.stats-display-body {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: flex-start;
    margin: 2px 8px;
}

.stats-display-element {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 10px;
}

.element-color-code {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin: 5px;
}

.element-info {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: #000000;

    /* width: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto; */
}