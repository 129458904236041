.description-info {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
}

.description-info__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    height: auto;
    text-align: left;
    margin-left: 12px;
}