.filter-button {
    display: flex;
    position: relative;
    height: 50px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 24px;
}

.filter-button__cancel {
    width: 120px;
    height: 32px;
    background: #BDBDBD;
    border-radius: 6px;
    outline: none;
    border: none;
    cursor: pointer;
}

.cancel-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #333333;
}

.filter-button__apply {
    width: 120px;
    height: 32px;
    background: #CC435F;
    border-radius: 6px;
    outline: none;
    border: none;
    cursor: pointer;
}

.apply-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}