.explainer-videos-main-page {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    overflow: hidden;
}

.explainer-videos-main-page__bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}