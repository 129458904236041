.activity-stack-main-bar {
  height: 100vh;
  /* width: 100%; */

  /* background: #EFF3FD; */
  display: flex;
  flex-direction: column;
  align-items: center;

  width: calc(100% - 260px);
}

.activity-stack-main-bar__header {
  position: fixed;
  /* top: 0px;
    left: 18%; */
  height: 24px;
  width: 100%;
  background: #ffffff;

  height: 5%;
  display: flex;
  float: left;
  align-self: flex-start;
}

.activity-stack-main-bar__filter-component {
  /* height: 12%;
  width: 100%; */

  position: relative;
  width: 100%;
  top: 5%;
  height: 5%;
}

.activity-stack-main-bar__stats-component {
  height: 18%;
  width: 100%;

  top: 6%;
  position: relative;
  background: #eff3fd;
}

.activity-stack-main-bar__table-component {
  /* height: 65%; */
  height: 70%;
  width: 100%;

  position: relative;
  top: 6%;
  height: 65%;
  width: 100%;
  background: #eff3fd;
}

.activity-stack-main-bar__pagination-component {
  height: 5%;
  width: 100%;

  width: 100%;
  background: #eff3fd;
  position: relative;
  top: 6%;
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}
